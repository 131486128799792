import { ADMISSION, CMR, CTC, DocumentStatuses, INVOICE, PASSPORT_INT, PASSPORT_RF } from './types'

export const CREATED = 'CREATED'
export const PAGINATION = 'PAGINATION'
export const PAGINATED = 'PAGINATED'
export const PAGINATION_FAILED = 'PAGINATION_FAILED'
export const CLASSIFICATION = 'CLASSIFICATION'
export const CLASSIFIED = 'CLASSIFIED'
export const CLASSIFICATION_FAILED = 'CLASSIFICATION_FAILED'
export const CLASSIFICATION_ALL_OTHERS = 'CLASSIFICATION_ALL_OTHERS'
export const RECOGNITION = 'RECOGNITION'
export const RECOGNIZED = 'RECOGNIZED'
export const RECOGNITION_FAILED = 'RECOGNITION_FAILED'
export const VERIFIED = 'VERIFIED'
export const REPORT_GENERATION = 'XLSX_GENERATION'
export const REPORT_GENERATED = 'XLSX_GENERATED'
export const REPORT_GENERATION_FAILED = 'XLSX_GENERATION_FAILED'

export const documentStatuses = {
	CREATED,
	PAGINATION,
	PAGINATED,
	PAGINATION_FAILED,
	CLASSIFICATION,
	CLASSIFICATION_FAILED,
	CLASSIFICATION_ALL_OTHERS,
	CLASSIFIED,
	RECOGNITION,
	RECOGNIZED,
	RECOGNITION_FAILED,
	VERIFIED,
	REPORT_GENERATION,
	REPORT_GENERATED,
	REPORT_GENERATION_FAILED,
} as const

export const documentStatusLabels: Record<DocumentStatuses, string> = {
	[CREATED]: 'Создан',
	[PAGINATION]: 'Разбивка на страницы',
	[PAGINATED]: 'Разбит на страницы',
	[PAGINATION_FAILED]: 'Разбивка на страницы: Ошибка',
	[CLASSIFICATION]: 'Классификация',
	[CLASSIFIED]: 'Классифицировано',
	[CLASSIFICATION_FAILED]: 'Классификация: Ошибка',
	[CLASSIFICATION_ALL_OTHERS]: 'Не обработан',
	[RECOGNITION]: 'Распознавание',
	[RECOGNIZED]: 'Распознано',
	[RECOGNITION_FAILED]: 'Распознавание: Ошибка',
	[VERIFIED]: 'Проверено',
	[REPORT_GENERATION]: 'Генерация результата',
	[REPORT_GENERATED]: 'Результат сгенерирован',
	[REPORT_GENERATION_FAILED]: 'Генерация результата: Ошибка',
} as const

export const FAILED_STATUSES = new Set([
	PAGINATION_FAILED,
	CLASSIFICATION_FAILED,
	CLASSIFICATION_ALL_OTHERS,
	RECOGNITION_FAILED,
	REPORT_GENERATION_FAILED,
])

export const PAGE_ITEMS = 'items'
export const PAGE_ATTRIBUTES = 'attributes'

// TODO узнать сколько лимит
export const UPLOADED_FILE_MAX_SIZE_MB = 300

export const INTERVAL_GET_PACKAGE_STATUS = 5000

export const CMR_LABELS: { [key in CMR]: string } = Object.freeze({
	[CMR.Sender]: 'Отправитель',
	[CMR.Consignee]: 'Получатель',
	[CMR.VehicleNo]: 'Номер транспортного средства',
	[CMR.Carrier]: 'Перевозчик',
	[CMR.Number]: 'Номер CRM',
	[CMR.Date]: 'Дата CRM',
	[CMR.LoadingCountry]: 'Страна отправитель',
	[CMR.SpecialAgreements]: 'Номера тягача и прицепа',
	[CMR.SenderInstuctions]: 'Указания отправителя',
})
export const INVOICE_LABELS: { [key in INVOICE]: string } = Object.freeze({
	[INVOICE.Number]: 'Номер',
	[INVOICE.Date]: 'Дата',
	[INVOICE.Currency]: 'Валюта',
	[INVOICE.Sender]: 'Отправитель',
	[INVOICE.Consignee]: 'Получатель',
	[INVOICE.TotalAmount]: 'Общая сумма',
	[INVOICE.TotalTax]: 'Общая сумма налога',
	[INVOICE.productCode]: 'Код международный',
	[INVOICE.productCodeTn]: 'Код ТН ВЭД',
	[INVOICE.description]: 'Описание товара',
	[INVOICE.quantity]: 'Количество',
	[INVOICE.grossWeight]: 'Вес брутто',
	[INVOICE.netWeight]: 'Вес нет',
	[INVOICE.taxRate]: 'Налоговая ставка',
	[INVOICE.tax]: 'Сумма налога',
	[INVOICE.unitPrice]: 'Цена за единицу',
	[INVOICE.amountLine]: 'Сумма всего',
	[INVOICE.unitType]: 'Единица измерения',
})

export const PASSPORT_RF_LABELS: { [key in PASSPORT_RF]: string } = Object.freeze({
	[PASSPORT_RF.SERIES]: 'Серия',
	[PASSPORT_RF.NUMBER]: 'Номер',
	[PASSPORT_RF.AUTHORITY_NAME]: 'Место выдачи',
	[PASSPORT_RF.ISSUE_DATE]: 'Дата выдачи',
	[PASSPORT_RF.AUTHORITY_CODE]: 'Код подразделения',
	[PASSPORT_RF.LAST_NAME]: 'Фамилия',
	[PASSPORT_RF.FIRST_NAME]: 'Имя',
	[PASSPORT_RF.MIDDLE_NAME]: 'Отчество',
	[PASSPORT_RF.GENDER]: 'Пол',
	[PASSPORT_RF.BIRTHPLACE]: 'Место рождения',
	[PASSPORT_RF.BIRTHDAY]: 'Дата рождения',
})
export const PASSPORT_INT_LABELS: { [key in PASSPORT_INT]: string } = Object.freeze({
	[PASSPORT_INT.Name]: 'Имя',
	[PASSPORT_INT.Surname]: 'Фамилия',
	[PASSPORT_INT.Nationality]: 'Гражданство',
	[PASSPORT_INT.Number]: 'Номер',
	[PASSPORT_INT.DateOfBirth]: 'Дата рождения',
	[PASSPORT_INT.DateOfIssue]: 'Дата выдачи',
	[PASSPORT_INT.DateOfExpiry]: 'Срок действия',
	[PASSPORT_INT.PlaceOfBirth]: 'Место рождения',
})
export const CTC_LABELS: { [key in CTC]: string } = Object.freeze({
	[CTC.Number]: 'Номер',
	[CTC.Reg_number_trailer]: 'Регистрационный номер прицепа',
	[CTC.Reg_number_car]: 'Регистрационный номер машины',
	[CTC.VIN]: 'VIN',
	[CTC.Weight]: 'Вес',
	[CTC.Mass]: 'Масса',
	[CTC.Year]: 'Год',
	[CTC.Engine_power]: 'Мощность двигателя',
})

export const ADMISSION_LABELS: { [key in ADMISSION]: string } = Object.freeze({
	[ADMISSION.Number]: 'Номер',
	[ADMISSION.Issue_date]: 'Дата выдачи',
	[ADMISSION.Valid_date]: 'Срок действия',
	[ADMISSION.Reg_number_trailer]: 'Регистрационный номер прицепа',
	[ADMISSION.Reg_number_car]: 'Регистрационный номер машины',
})
